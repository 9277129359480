import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';

// Actions

// Utils
import { validateControl } from '../../../utils/schemaBuilderUtils';

// Components
import TextInput from '../../Custom/Forms/TextInput';
import LastEditor from '../../Custom/LastEditor';

const ControlItemNumbers = props => {
  const { control, disabled, areControlsValid } = props;
  const {
    title,
    alternativeTitle,
    numberValue,
    infoTitle,
    infoText,
    showInfoIcon,
    required,
    placeholder,
    errorMessage,
    answeredByUserId,
    updatedDate,
  } = control;
  const [valid, setValid] = useState(true);
  const labelSize = 3;
  const inputSize = 9;

  useEffect(() => {
    setValid(validateControl(control));
    // eslint-disable-next-line
  }, [numberValue]);

  const onChange = e => {
    props.onChange(e);
  };

  const renderInfo = () => {
    if (!showInfoIcon) return null;

    return {
      title: infoTitle,
      html: infoText,
    };
  };

  const renderTitle = () => {
    let tmp = title;
    if (alternativeTitle?.trim().length > 0) tmp = alternativeTitle;

    if (required) tmp += '*';

    return tmp;
  };

  const titleValid = () => {
    if (valid || areControlsValid || !required) return null;

    return (
      <div>
        <p style={{ color: 'red' }}>{errorMessage}</p>
      </div>
    );
  };

  return (
    <TextInput
      row
      for={renderTitle()}
      labelError={titleValid()}
      subtitle={
        <LastEditor
          answeredByUserId={answeredByUserId}
          updatedDate={updatedDate}
        />
      }
      name='numberValue'
      type='number'
      labelSize={labelSize}
      inputSize={inputSize}
      value={numberValue}
      placeholder={placeholder}
      onChange={onChange}
      info={renderInfo()}
      disabled={disabled}
    />
  );
};

function mapStateToProps({ auth, schemas, cases }) {
  return {
    auth,
    schemas,
    cases,
  };
}

export default connect(mapStateToProps, {})(ControlItemNumbers);
