import React from 'react';
import i18next from '../../i18n';
import DatePicker, { registerLocale } from 'react-datepicker';
import { Label } from 'reactstrap';
import 'react-datepicker/dist/react-datepicker.css';
import dk from 'date-fns/locale/da';
import en from 'date-fns/locale/en-GB';
import de from 'date-fns/locale/de';

// Utils
import { MdClear } from 'react-icons/md';

// Misc
registerLocale('dk', dk);
registerLocale('en', en);
registerLocale('de', de);

const globalLang = window.localStorage.getItem('user_lang');
const ReactDatePicker = props => {
  const { label, wrapperClass, value } = props;
  const format = props.format ? props.format : 'yyyy.MM.dd';
  let locale = props.locale ? props.locale : 'dk';

  if (globalLang) {
    locale = globalLang;
  }

  const placeholderText = props.placeholder
    ? props.placeholder
    : `${i18next.t(1030)} ${i18next.t(1061)?.toLowerCase()}`;

  const className = props.className ? props.className : ' form-control';

  let labelContent = null;

  const picker = (
    <DatePicker
      {...props}
      selected={value}
      className={className}
      dateFormat={format}
      locale={locale}
      placeholderText={placeholderText}
      showWeekNumbers
    />
  );

  if (label) {
    labelContent = <Label>{i18next.t(label)}</Label>;
  }

  return (
    <div
      className={
        'custom-datepicker cr-search-form form-inline' +
        (wrapperClass ? ' ' + wrapperClass : '')
      }
    >
      {labelContent}
      {picker}
      {props.onChange && value && !props.disabled && (
        <span className='date-clear' onClick={() => props.onChange(null)}>
          <MdClear size={20} />
        </span>
      )}
    </div>
  );
};

export default ReactDatePicker;
