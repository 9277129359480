import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';

// Actions

// Utils
import { validateControl } from '../../../utils/schemaBuilderUtils';

// Components
import CustomCheckbox from '../../Custom/Forms/Checkbox';
import InfoTip from '../../Custom/InfoTip';
import Typography from '../../Typography';
import LastEditor from '../../Custom/LastEditor';

const ControlItemDropdownCheckboxes = props => {
  const { control, disabled, areControlsValid } = props;
  const {
    title,
    alternativeTitle,
    infoTitle,
    infoText,
    showInfoIcon,
    required,
    checkboxOptions,
    checkboxValues,
    multipleChoice,
    errorMessage,
    answeredByUserId,
    updatedDate,
  } = control;
  const [valid, setValid] = useState(true);
  const labelSize = 3;
  const inputSize = 9;

  useEffect(() => {
    setValid(validateControl(control));
    // eslint-disable-next-line
  }, [checkboxValues]);

  const onChange = (name, value) => {
    props.onChange({
      target: {
        name,
        value,
      },
    });
  };

  const onChangeCheckbox = (id, state) => {
    // state = false = add
    // state = true = remove

    let options = [];
    if (multipleChoice) {
      if (checkboxValues?.trim().length > 0) {
        options = checkboxValues
          .split(',')
          .filter(x => !!x || x?.trim().length > 0);
      }
    }

    if (state) {
      options = options.filter(x => x !== id + '');
    }

    if (!state) {
      options.push(id + '');
    }

    const val = options.reduce((prev, cur) => prev + cur + ',', '');

    onChange('checkboxValues', val);
  };

  const renderInfo = () => {
    if (!showInfoIcon) return null;
    const targetId = 'tt_cid_' + control.id;

    return <InfoTip id={targetId} title={infoTitle} html={infoText} />;
  };

  const renderCheckboxes = () => {
    return checkboxOptions.map((box, index) => {
      let checked = box.value;

      if (checkboxValues?.length > 0) {
        const split = checkboxValues
          .split(',')
          .filter(x => !!x || x?.trim().length > 0);

        checked = split.includes(box.sbId + '');
      }

      return (
        <div key={index}>
          <CustomCheckbox
            row
            className='mt-2'
            disabled={disabled}
            for={box.label}
            labelSize={labelSize}
            inputSize={inputSize}
            name={`box-${index}`}
            onChange={() => onChangeCheckbox(box.sbId, checked)}
            checked={checked}
          />
        </div>
      );
    });
  };

  const renderTitle = () => {
    let tmp = title;
    if (alternativeTitle?.trim().length > 0) tmp = alternativeTitle;

    if (required) tmp += '*';

    return tmp;
  };

  const titleValid = () => {
    if (valid || areControlsValid || !required) return null;

    return (
      <div>
        <p style={{ color: 'red' }}>{errorMessage}</p>
      </div>
    );
  };

  return (
    <div>
      <Typography>
        <div className='label-wrap'>
          {renderTitle()}
          {renderInfo()}
        </div>
        {titleValid()}
        <LastEditor
          answeredByUserId={answeredByUserId}
          updatedDate={updatedDate}
        />
      </Typography>
      {renderCheckboxes()}
    </div>
  );
};

function mapStateToProps({ schemas, cases }) {
  return {
    schemas,
    cases,
  };
}

export default connect(mapStateToProps, {})(ControlItemDropdownCheckboxes);
