import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';

// Actions

// Utils
import { validateControl } from '../../../utils/schemaBuilderUtils';

// Components
import TextInput from '../../Custom/Forms/TextInput';
import { FormText } from 'reactstrap';
import LastEditor from '../../Custom/LastEditor';
import { useRef } from 'react';

const ControlItemText = props => {
  const { control, disabled, areControlsValid } = props;
  const {
    title,
    alternativeTitle,
    textValue,
    infoTitle,
    infoText,
    showInfoIcon,
    maxCharacters,
    required,
    placeholder,
    errorMessage,
    answeredByUserId,
    updatedDate,
  } = control;
  const [valid, setValid] = useState(true);
  const labelSize = 3;
  const inputSize = 9;
  const [localText, setText] = useState(textValue);
  const textTimeout = useRef(0);

  useEffect(() => {
    setValid(validateControl(control));
    // eslint-disable-next-line
  }, [textValue]);

  const onChange = e => {
    let text = e.target.value;
    setText(text);
    if (textTimeout.current) {
      clearTimeout(textTimeout.current);
    }
    textTimeout.current = setTimeout(() => {
      props.onChange({ target: { name: 'textValue', value: text } });
    }, 300);
  };

  const renderInfo = () => {
    if (!showInfoIcon) return null;

    return {
      title: infoTitle,
      html: infoText,
    };
  };

  const renderMaxChars = () => {
    if (!maxCharacters || maxCharacters === 0) return null;
    const count = textValue?.length ?? 0;

    return (
      <FormText>
        {count}/{maxCharacters}
      </FormText>
    );
  };

  const renderTitle = () => {
    let tmp = title;
    if (alternativeTitle?.trim().length > 0) tmp = alternativeTitle;

    if (required) tmp += '*';

    return tmp;
  };

  const titleValid = () => {
    if (valid || areControlsValid || !required) return null;

    return (
      <div>
        <p style={{ color: 'red' }}>{errorMessage}</p>
      </div>
    );
  };

  return (
    <TextInput
      row
      for={renderTitle()}
      labelError={titleValid()}
      subtitle={
        <LastEditor
          answeredByUserId={answeredByUserId}
          updatedDate={updatedDate}
        />
      }
      name='textValue'
      type='textarea'
      labelSize={labelSize}
      inputSize={inputSize}
      value={localText}
      placeholder={placeholder}
      onChange={onChange}
      info={renderInfo()}
      extra={renderMaxChars()}
      disabled={disabled}
    />
  );
};

function mapStateToProps({ auth, schemas, cases }) {
  return {
    auth,
    schemas,
    cases,
  };
}

export default connect(mapStateToProps, {})(ControlItemText);
