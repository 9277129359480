import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import i18next from '../../../i18n';
import { Col, FormGroup, Label } from 'reactstrap';

// Actions
import {
  uploadFiles,
  deleteFile,
  updateFileInfo,
  setGalleryImages,
} from '../../../redux/actions/cases';
import { addImageToControlItem } from '../../../redux/actions/schemas';

// Utils
import { uriToFile } from '../../../utils/fileUtils';
import { validateControl } from '../../../utils/schemaBuilderUtils';
import { CASE_FILE_TYPE } from '../../../redux/constants';

// Components
import Typography from '../../Typography';
import FileInput from '../../Custom/Forms/FileInput';
import Gallery from '../../Custom/Gallery';
import InfoTip from '../../Custom/InfoTip';
import GalleryPicker from '../../Custom/GalleryPicker';
import LastEditor from '../../Custom/LastEditor';

const ControlItemPhotoArea = props => {
  const {
    building,
    schema,
    control,
    disabled,
    onChangeArray,
    setUpdating,
    uploadFiles,
    deleteFile,
    updateFileInfo,
    addImageToControlItem,
    selectControl,
    areControlsValid,
    isMovingImages,
  } = props;
  const {
    title,
    alternativeTitle,
    infoTitle,
    infoText,
    showInfoIcon,
    required,
    files,
    errorMessage,
    answeredByUserId,
    updatedDate,
  } = control;
  const { selectedCase, caseImages } = props.cases;
  const [valid, setValid] = useState(true);
  const [errors, setErrors] = useState([]);
  const [isUploading, setUploading] = useState(false);
  const isServiceAgreement = selectedCase?.type === 3;
  const labelSize = 3;
  // const inputSize = 9;
  let tmpFiles = [];
  if (files?.length > 0) {
    tmpFiles = [files[0]];
  }

  useEffect(() => {
    setValid(validateControl(control));
    // eslint-disable-next-line
  }, [files]);

  const onChange = val => {
    onChangeArray('files', val);
  };

  const renderInfo = () => {
    if (!showInfoIcon) return null;
    const targetId = 'tt_cid_' + control.id;

    return <InfoTip id={targetId} title={infoTitle} html={infoText} />;
  };

  /////////////////////////////////////////////////////////////////////////////////////////////////
  ////// file handling methods

  const onDropAccepted = (images, description = null, title = null) => {
    setUpdating(true);
    setUploading(true);

    let obj = {
      controlItemId: control.id,
    };

    if (isServiceAgreement) {
      obj.serviceAgreementId = selectedCase.id;
    } else {
      obj.qualityCaseId = selectedCase.id;
    }

    uploadFiles(images, obj)
      .then(res => {
        if (description) {
          updateFileInfo(res[0].id, { description, title });
          res[0].fileDescription = description;
        }
        onChange(res);
        setUpdating(false);
        setUploading(false);
      })
      .catch(err => {
        let errData;
        if (err.response) {
          errData = err.response.data;
        } else {
          errData = err;
        }
        console.log('file error', errData);
        setErrors(errData.fileInvalid || [i18next.t(142)]);
        setUpdating(false);
        setUploading(false);
      });
  };

  const saveFile = (oldFile, newFile) => {
    setUpdating(true);
    // Delete the old file,
    deleteFile(oldFile.id).then(() => {
      const newFiles = files.filter(f => f.id !== oldFile.id);
      onChange(newFiles);
    });

    // Save the new file
    let file = uriToFile(newFile, oldFile.fileName);
    //console.log(file);
    onDropAccepted([file], oldFile.fileDescription, oldFile.fileTitle);
  };

  const onDelete = fileId => {
    setUpdating(true);
    deleteFile(fileId).then(() => {
      const newFiles = files.filter(f => f.id !== fileId);

      onChange(newFiles);
      setUpdating(false);
    });
  };

  const handleInfo = (fileId, description, title) => {
    //console.log(fileId, description);
    let file = files.find(x => x.id === fileId);
    file.fileDescription = description;
    file.fileTitle = title;
    let newFiles = files.filter(x => x.id !== fileId);
    newFiles.push(file);
    newFiles.sort((a, b) => (a.id > b.id ? 1 : -1));

    onChange(newFiles);
  };

  /////////////////////////////////////////////////////////////////////////////////////////////////
  //////// Image marking

  const onAddImageToControl = image => {
    setUploading(true);

    const dataObj = {
      imageId: image.id,
      controlId: control.id,
      caseId: selectedCase.id,
      isServiceAgreement,
    };

    addImageToControlItem(dataObj)
      .then(res => {
        onChange([res.data]);
        setUploading(false);
      })
      .catch(err => {
        console.log(err);
        setUploading(false);
      });
  };

  /////////////////////////////////////////////////////////////////////////////////////////////////

  const renderErrors = () => {
    return (
      <>
        {errors.map((error, key) => (
          <Typography className='text-danger' key={key}>
            {error}
          </Typography>
        ))}
      </>
    );
  };

  const renderTitle = () => {
    let tmp = title;
    if (alternativeTitle?.trim().length > 0) tmp = alternativeTitle;

    if (required) tmp += '*';

    return tmp;
  };

  const titleValid = () => {
    if (valid || areControlsValid || !required) return null;

    return (
      <div>
        <p style={{ color: 'red' }}>{errorMessage}</p>
      </div>
    );
  };

  let galleryComp;
  const allowedTypes = [
    CASE_FILE_TYPE.KSFixingPlan,
    CASE_FILE_TYPE.BuildingImage,
  ];
  let filteredFiles = caseImages.filter(x =>
    allowedTypes.includes(x.documentType),
  );

  if (building) {
    filteredFiles = filteredFiles.filter(
      x => x.caseBuildingId === building.value || !x.caseBuildingId,
    );
  }

  if (filteredFiles.length > 0 && files.length === 0) {
    galleryComp = (
      <GalleryPicker
        files={filteredFiles}
        onImageClick={onAddImageToControl}
        isSaving={isUploading}
      />
    );
  }

  return (
    <FormGroup row>
      <Label sm={labelSize} size='md'>
        <div className='label-wrap'>
          {renderTitle()}
          {renderInfo()}
        </div>
        {titleValid()}
        <LastEditor
          answeredByUserId={answeredByUserId}
          updatedDate={updatedDate}
        />
      </Label>
      <Col>
        {!schema.isDone && files.length === 0 && !selectControl && (
          <>
            <FileInput
              name={'files'}
              onDropAccepted={onDropAccepted}
              isUploading={isUploading}
              gallery={galleryComp}
              disabled={disabled}
            />
            <small
              className='text-muted'
              style={{ display: 'block', marginTop: -10, marginBottom: 10 }}
            >
              {i18next.t(9117)}
            </small>
          </>
        )}

        {renderErrors()}

        <Gallery
          files={tmpFiles}
          onDelete={onDelete}
          saveFile={saveFile}
          handleInfo={handleInfo}
          disabled={disabled}
          schema={schema}
          control={control}
          imageHash={props.imageHash}
          hasAreaMarking={true}
          isMovingImages={isMovingImages}
          konva
        />
      </Col>
    </FormGroup>
  );
};

function mapStateToProps({ auth, schemas, cases, common }) {
  return {
    auth,
    schemas,
    cases,
    imageHash: common.imageHash,
  };
}

export default connect(mapStateToProps, {
  uploadFiles,
  deleteFile,
  updateFileInfo,
  setGalleryImages,
  addImageToControlItem,
})(ControlItemPhotoArea);
