// API URL
export const API_URL = process.env.REACT_APP_API_URL;

// Third Party API
export const POSTAL_API = 'https://dawa.aws.dk/';

// Language constants
export const GET_LANGUAGES = 'GET_LANGUAGES';
export const SET_LANGUAGES = 'SET_LANGUAGES';

// Auth constants
export const SET_CURRENT_USER = 'SET_CURRENT_USER';
export const SET_USER_PERMISSIONS = 'SET_USER_PERMISSIONS';
export const SET_LOGIN_AS = 'SET_LOGIN_AS';
export const LOGOUT_AS = 'LOGOUT_AS';
export const SET_PAUSED_MODAL = 'SET_PAUSED_MODAL';
export const HANDLED_PAUSED_MODAL = 'HANDLED_PAUSED_MODAL';
export const SET_CUSTOMER_STATUS = 'SET_CUSTOMER_STATUS';
export const SET_PERMISSION_SET = 'SET_PERMISSION_SET';

// Cases
export const SET_CASE = 'SET_CASE';
export const SET_CASES_ALL = 'SET_CASES_CALL';
export const SET_CASES_WARM_WORK = 'SET_CASES_WARM_WORK';
export const SET_CASES_QUALITY_WORK = 'SET_CASES_QUALITY_WORK';
export const SET_CASE_IMAGE_GALLERY = 'SET_CASE_IMAGE_GALLERY';

// Service Agreements
export const SET_SERVICE_AGREEMENTS = 'SET_SERVICE_AGREEMENTS';
export const SET_SERVICE_AGREEMENT = 'SET_SERVICE_AGREEMENT';

// Customer Group constants
export const SET_CUSTOMERGROUPS = 'SET_CUSTOMERGROUPS';

// System Constants
export const SET_SYSTEM_INFO = 'SET_SYSTEM_INFO';
export const SET_SYSTEM_MESSAGE = 'SET_SYSTEM_MESSAGE';

// Customer constants
export const SET_CUSTOMERS = 'SET_CUSTOMERS';
export const SET_CUSTOMER = 'SET_CUSTOMER';

// Sales manager constants
export const SET_SALES_MANAGERS = ' SET_SALES_MANAGERS';
export const SET_SALES_MANAGER = ' SET_SALES_MANAGER';

// Employee constants
export const SET_EMPLOYEES = 'SET_EMPLOYEES';

// Insurance companies constants
export const SET_INSURANCE_COMPANIES = 'SET_INSURANCE_COMPANIES';

// Features
export const GET_FEATURES = 'GET_FEATURES';
export const ADD_FEATURES = 'ADD_FEATURES';
export const UPDATE_FEATURES = 'UPDATE_FEATURES';
export const GRANT_FEATURES = 'GRANT_FEATURES';

// Schemabuilder
export const SB_GET_SCHEMAS_WITH_INDEX = 'SB_GET_SCHEMAS_WITH_INDEX';
export const SB_ADD_SCHEMA_TO_CATEGORY = 'SB_ADD_SCHEMA_TO_CATEGORY';
export const SB_UPDATE_SCHEMA_IN_CATEGORY = 'SB_UPDATE_SCHEMA_IN_CATEGORY';
export const SB_REMOVE_SCHEMA_FROM_CATEGORY = 'SB_REMOVE_SCHEMA_FROM_CATEGORY';

export const SB_GET_SCHEMA_PACKAGES_INDICE = 'SB_GET_SCHEMA_PACKAGES_INDICE';
export const SB_ADD_SCHEMA_PACKAGE = 'SB_ADD_SCHEMA_PACKAGE';
export const SB_UPDATE_SCHEMA_PACKAGE = 'SB_UPDATE_SCHEMA_PACKAGE';
export const SB_REMOVE_SCHEMA_PACKAGE = 'SB_REMOVE_SCHEMA_PACKAGE';

export const SB_SET_CURRENT_SCHEMA_CATEGORY = 'SB_SET_CURRENT_SCHEMA_CATEGORY';
export const SB_GET_SCHEMA_CATEGORIES = 'SB_GET_SCHEMA_CATEGORIES';
export const SB_ADD_SCHEMA_CATEGORY = 'SB_ADD_SCHEMA_CATEGORY';
export const SB_UPDATE_SCHEMA_CATEGORY = 'SB_UPDATE_CATEGORY_IN_PACKAGES';
export const SB_REMOVE_SCHEMA_CATEGORY = 'SB_REMOVE_SCHEMA_CATEGORY';

export const SB_SET_CURRENT_SCHEMA_PACKAGE = 'SB_SET_CURRENT_SCHEMA_PACKAGE';
export const SB_GET_PACKAGE_SCHEMAS = 'SB_GET_PACKAGE_SCHEMAS';
export const SB_ADD_SCHEMA_TO_PACKAGE = 'SB_ADD_SCHEMA_TO_PACKAGE';
export const SB_UPDATE_SCHEMA_IN_PACKAGE = 'SB_UPDATE_SCHEMA_IN_PACKAGE';
export const SB_REMOVE_SCHEMA_FROM_PACKAGE = 'SB_REMOVE_SCHEMA_FROM_PACKAGE';

export const SB_GET_SCHEMAS = 'SB_GET_SCHEMAS';
export const SB_GET_SCHEMA = 'SB_GET_SCHEMA';
export const SB_ADD_SCHEMA = 'SB_ADD_SCHEMA';
export const SB_UPDATE_SCHEMA = 'SB_UPDATE_SCHEMA';
export const SB_REMOVE_SCHEMA = 'SB_REMOVE_SCHEMA';
export const SB_SET_CURRENT_SCHEMA = 'SB_SET_CURRENT_SCHEMA';

export const SB_GET_RELATION_CONTROLS = 'SB_GET_RELATION_CONTROLS';
export const SB_ADD_RELATION_CONTROL = 'SB_ADD_RELATION_CONTROL';
export const SB_UPDATE_RELATION_CONTROL = 'SB_UPDATE_RELATION_CONTROL';
export const SB_REMOVE_RELATION_CONTROL = 'SB_REMOVE_RELATION_CONTROL';
export const SB_SET_RELATION_CONTROL = 'SB_SET_RELATION_CONTROL';

export const SB_GET_RELATION_GROUPS = 'SB_GET_RELATION_GROUPS';
export const SB_SET_RELATION_GROUP = 'SB_SET_RELATION_GROUP';

export const SB_GET_CRITERIA = 'SB_GET_CRITERIA';
export const SB_ADD_CRITERIA = 'SB_ADD_CRITERIA';
export const SB_UPDATE_CRITERIA = 'SB_UPDATE_CRITERIA';
export const SB_REMOVE_CRITERIA = 'SB_REMOVE_CRITERIA';

export const SB_GET_CONTROL_ITEMS = 'SB_GET_CONTROL_ITEMS';
export const SB_GET_CONTROL_ITEM = 'SB_GET_CONTROL_ITEM';
export const SB_ADD_CONTROL_ITEM = 'SB_ADD_CONTROL_ITEM';
export const SB_UPDATE_CONTROL_ITEM = 'SB_UPDATE_CONTROL_ITEM';
export const SB_REMOVE_CONTROL_ITEM = 'SB_REMOVE_CONTROL_ITEM';
export const SB_SET_CONTROL_ITEM = 'SB_SET_CONTROL_ITEM';

// Control Schemas
export const GET_SCHEMA_GROUP = 'GET_SCHEMA_GROUP';
export const SET_SCHEMA_GROUP = 'SET_SCHEMA_GROUP';
export const SET_CURRENT_SCHEMA = 'SET_CURRENT_SCHEMA';
export const GET_SCHEMA_OPTIONS = 'GET_SCHEMA_OPTIONS';
export const SET_MARKED_SCHEMAS = 'SET_MARKED_SCHEMAS';
export const REFRESH_SCHEMA_GROUP = 'REFRESH_SCHEMA_GROUP';

// Control plan
export const GET_CONTROLPLANS = 'GET_CONTROLPLANS';
export const SET_CONTROLPLANS = 'SET_CONTROLPLANS';
export const GET_CONTROLPLAN = 'GET_CONTROLPLAN';
export const SET_CONTROLPLAN = 'SET_CONTROLPLAN';
export const GET_CONTROLPLAN_ON_CASE = 'GET_CONTROLPLAN_ON_CASE';
export const SET_CONTROLPLAN_ON_CASE = 'SET_CONTROLPLAN_ON_CASE';

// Common constants
export const SET_HEADERINFO = 'SET_HEADERINFO';
export const SET_USER_LANGUAGE = 'SET_USER_LANGUAGE';
export const SET_BRANDING = 'SET_BRANDING';
export const GET_CUSTOMER_BRANDING = 'GET_CUSTOMER_BRANDING';
export const SET_CUSTOMER_BRANDING = 'SET_CUSTOMER_BRANDING';
export const ADD_TRACE_LOG = 'ADD_TRACE_LOG';
export const TOA_HANDLED = 'TOA_HANDLED'; // Terms of Agreement Handled
export const SET_SA_FILTER = 'SET_SA_FILTER';
export const GET_SA_FILTER = 'GET_SA_FILTER';
export const SET_RM_FILTER = 'SET_RM_FILTER';
export const GET_RM_FILTER = 'GET_RM_FILTER';
export const SET_RM_MATS_FILTER = 'SET_RM_MATS_FILTER';
export const GET_RM_MATS_FILTER = 'GET_RM_MATS_FILTER';
export const GET_USER_FILTERS = 'GET_USER_FILTERS';
export const UPDATE_USER_FILTERS = 'UPDATE_USER_FILTERS';
export const GET_AGREEMENT_SCHEMA_SETTINGS = 'GET_AGREEMENT_SCHEMA_SETTINGS';
export const UPDATE_AGREEMENT_SCHEMA_SETTING =
  'UPDATE_AGREEMENT_SCHEMA_SETTING';
export const GET_BINDINGS = 'GET_BINDINGS';
export const GET_HIDE_MODAL_SETTINGS = 'GET_HIDE_MODAL_SETTINGS';
export const UPDATE_HIDE_MODAL_SETTINGS = 'UPDATE_HIDE_MODAL_SETTINGS';
export const GET_PRODUCTS = 'GET_PRODUCTS';
export const GET_CASE_MATERIALS = 'GET_CASE_MATERIALS';
export const IMAGE_HASH_REFRESH = 'IMAGE_HASH_REFRESH';

//Documents
export const GET_FOLDERS = 'GET_FOLDERS';
export const GET_CURRENT_FOLDER = 'GET_CURRENT_FOLDER';
export const SET_CURRENT_FOLDER = 'SET_CURRENT_FOLDER';
export const ADD_FOLDERS = 'ADD_FOLDERS';
export const UPDATE_FOLDERS = 'UPDATE_FOLDERS';
export const DELETE_FOLDERS = 'DELETE_FOLDERS';
export const GET_FILES = 'GET_FILES';
export const ADD_FILES = 'ADD_FILES';
export const UPDATE_FILES = 'UPDATE_FILES';
export const DELETE_FILES = 'DELETE_FILES';
export const GET_FAVORITES = 'GET_FAVORITES';
export const ADD_FAVORITES = 'ADD_FAVORITES';
export const UPDATE_FAVORITES = 'UPDATE_FAVORITES';
export const DELETE_FAVORITES = 'DELETE_FAVORITES';

// Roadmap
export const GET_ROADMAP_DATA = 'GET_ROADMAP_DATA';
export const GET_ROADMAP_MATERIALS_DATA = 'GET_ROADMAP_MATERIALS_DATA';
export const SET_ROADMAP_DATE_INTERVAL = 'SET_ROADMAP_DATE_INTERVAL';
export const RENDERED_ROADMAP_TIMELINE = 'RENDERED_ROADMAP_TIMELINE';
export const RENDERED_ROADMAP_MATERIALS = 'RENDERED_ROADMAP_MATERIALS';
export const RENDERED_ROADMAP_TIMEINDICATOR = 'RENDERED_ROADMAP_TIMEINDICATOR';
export const RERENDER_ROADMAP_LOCK_STATE = 'RERENDER_ROADMAP_LOCK_STATE';
export const GET_ROADMAP_INFO_SCREEN_DATA = 'GET_ROADMAP_INFO_SCREEN_DATA';
export const TOGGLE_ROADMAP_INFO_SCREEN = 'TOGGLE_ROADMAP_INFO_SCREEN';
export const SET_SIDEMENU_ERRORS = 'SET_SIDEMENU_ERRORS';
export const ROADMAP_SET_INFOBAR_DATA = 'ROADMAP_SET_INFOBAR_DATA';

// Notifications
export const GET_NOTIFICATIONS = 'GET_NOTIFICATIONS';
export const MARK_NOTIFICATION = 'MARK_NOTIFICATION';
export const GET_NOTIFICATION_SETTINGS = 'GET_NOTIFICATION_SETTINGS';
export const UPDATE_NOTIFICATION_SETTINGS = 'UPDATE_NOTIFICATION_SETTINGS';

// Case descriptions
export const GET_CASE_DESCRIPTIONS = 'GET_CASE_DESCRIPTIONS';
export const GET_CUSTOMER_DESCRIPTIONS = 'GET_CUSTOMER_DESCRIPTIONS';
export const SET_CASE_DESCRIPTIONS = 'SET_CASE_DESCRIPTIONS';
export const SET_CUSTOMER_DESCRIPTIONS = 'SET_CUSTOMER_DESCRIPTIONS';

// Image marking
export const GET_ALL_IMAGE_REFERENCES = 'GET_ALL_IMAGE_REFERENCES';
export const SET_CURRENT_IMAGE_REFERENCES = 'SET_CURRENT_IMAGE_REFERENCES';
export const UPDATE_REFERENCE_INFO = 'UPDATE_REFERENCE_INFO';
export const IS_REF_SAVE_ALLOWED = 'IS_REF_SAVE_ALLOWED';

// Icon constants
export const DEFAULT_ICON_COLOR = '#003341';

// Months
export const MONTHS = {
  January: 1,
  February: 2,
  March: 3,
  April: 4,
  May: 5,
  June: 6,
  July: 7,
  August: 8,
  September: 9,
  October: 10,
  November: 11,
  December: 12,
};

// Employee Type constants
export const EMPLOYEE_TYPE = {
  None: 0,
  SuperAdmin: 1,
  LocalAdmin: 2,
  ResponsiblePerson: 3,
  // Landlord: 4, // Unused - but kept in list
  ExecutivePerson: 5,
  BuildingOwner: 6,
  Firewatch: 7,
  Subcontractor: 8,
  RoofingContractor: 9,
  Advisor: 10,
  Architect: 11,
  InternalSubcontractor: 12,
  Supplier: 13,
  CompanyOwner: 14,
};

export const USER_POSITION_TYPE = {
  None: 0,
  RoofingContractor: 1,
  EnterpriseLeader: 2,
  Director: 3,
  DepartmentLead: 4,
  Planner: 5,
  Calculator: 6,
  Advisor: 7,
  Architect: 8,
  Specialist: 9,
  Supplier: 10,
  Customer: 11,
  Student: 12,
  Other: 13,
};

// AccessLevel constants
export const ACCESS_LEVEL = {
  SuperAdmin: 1,
  LocalAdmin: 2,
  CustomerAdmin: 3,
  Employee: 4,
  None: 100,
};

// Assignee Types
export const ASSIGNEE_TYPE = {
  ExecutivePerson: 1,
  Firewatch: 2,
  BuildingOwner: 3,
  ExecutiveResponsible: 4,
  Subcontractor: 5,
  // ContactForCompany: 6,
  // ContactForProjectOrganisation: 7,
  // ContactForSubcontractor: 8,
  ContactForInternalOrganisation: 9,
  // ContactForEmployee: 10,
  Employee: 11,
  RoofingContractor: 12,
  Advisor: 13,
  Architect: 14,
  Supplier: 15,
  None: 100,
};

// Used in conjunction with contaction information
export const SIMPLE_USERS = [
  EMPLOYEE_TYPE.BuildingOwner,
  EMPLOYEE_TYPE.Firewatch,
  EMPLOYEE_TYPE.RoofingContractor,
  EMPLOYEE_TYPE.Subcontractor,
];

// Business Intelligence Search types
export const BI_SEARCH_TYPES = {
  Unknown: 0,
  CurrentCasesVA: 1,
  CurrentCasesKS: 2,
  // Users: 3,
  Companies: 4,
  CompanyUsers: 5,
  CompanyUsersInternal: 6,
  CompanyUsersExternal: 7,
  UsersWithoutBindings: 8,
  CurrentCases: 12,
  CurrentCasesSA: 13,
  ControlSchemas: 14,
};

// Template types
export const TEMPLATE = {
  GlobalMaster: 1,
  // GroupMaster: 2, // not yet implemented
  CustomerMaster: 3,
  Template: 4,
};

// Template section types
export const TEMPLATE_SECTIONS = {
  None: 0,
  RecieveControl: 1,
  ExecutiveControl: 2,
  FinalControl: 3,
  RunnerUpControl: 4,
  ServiceAgreement: 5,
};

// Expire date types
export const EXPIRE_TYPES = {
  Hour: 0,
  Day: 1,
  Week: 2,
  Month: 3,
  Year: 6,
  Permanent: 4,
  Custom: 5,
};

// Description types
export const DESCRIPTION_TYPE = {
  Customer: 0,
  OperationAndMaintenance: 1,
  QualityInsuranceDescription: 2,
};

// Hide Modal types
export const HIDE_MODAL_TYPES = {
  PdfGenerationModalWeb: 0,
  PdfGenerationModalApp: 1,
};

// System Message Types
export const SYSTEM_MESSAGE_TYPE = {
  STANDARD: 0,
  INFORMATIVE: 1,
  WARNING: 2,
  URGENT: 3,
  STANDARD_PRIMARY: 4,
};

// Error types
export const ERROR_TYPE = {
  ApiError: 0,
  WebError: 1,
};
// Team Update Types
export const TEAM_UPDATE = {
  TeamCreate: 0,
  TeamUpdate: 1,
  TeamDelete: 2,
  AddMember: 100,
  RemoveMember: 101,
};

// System Push message types
export const PUSH_TYPE = {
  None: 0,
  Users: 1,
  Teams: 2,
  Companies: 3,
  Groups: 4,
  Global: 5,
};

// Device Types
export const DEVICE_TYPE = {
  iOS: 1,
  iPhoneOS: 2,
  Android: 3,
  None: 100,
};

// Case states
export const CASE_STATUS = {
  NotAccepted: 1,
  Accepted: 2,
  InProgress: 3,
  Done: 4,
  Created: 5, // Quality Insurance Case
  Active: 6, // Quality Insurance Case
  Invoiced: 7,
  MissingControls: 8,
  IncompleteControl: 9,
  CompletedControl: 10,
  Subscribed: 11,
  Unsubscribed: 12,
  Draft: 99,
  None: 100,
  NullState: 1000,
};

export const CASE_FILE_TYPE = {
  Unknown: 0,
  Case: 1,
  QualtiyCase: 2,
  CoverImage: 3,
  Contract: 4,
  CompressedImage: 5,
  ControlImage: 6,
  BuildingImage: 7,
  KSGenericDocument: 20000,
  KSFixingPlan: 20001,
};

// Search categories
export const SEARCH_CATEGORY = {
  All: 0,
  New: 1,
  Archive: 2,
};

// Shapes
export const MARKING_TYPE = {
  Square: 1,
  Circle: 2,
};

// Features
export const FEATURE = {
  Hotwork: 0,
  QualityInsurance: 1,
  ServiceAgreement: 2,
  Documents: 3,
  DocumentsBasic: 4,
  Roadmap: 5,
  Materials: 6,
  SBSchemas: 7,
  PdfSchemaPrinting: 8,
};

export const FEATURE_SUB = {
  Standard: 0,
  Basis: 1,
  Plus: 2,
};

export const FEATURE_STATUS = {
  Unset: 0,
  Trial: 1,
  Purchased: 2,
  Disabled: 3,
};

// Customer states
export const CUSTOMER_STATUS = {
  Active: 1,
  Closed: 0,
  Paused: 3,
  // Trial: 2, // Not yet implemented
};

// Insurances
export const CUSTOM_ASSIGNMENT_TYPE_INSURANCES = {
  Koebstaedernes_forsikring: 1,
};

// Insurance type
export const INSURANCE_TYPE = {
  DBI_Forsikring: 1,
  Koebstaedernes_forsikring: 2,
};

// Assignment schemas
export const ASSIGNMENT_SCHEMA = {
  None: 100,
  WarmWorkStandard: 1,
  QualityWorkStandard: 2,
  WarmWorkStandardDBI: 101,
  WarmWorkStandardDBI_2021: 102,
  WarmWorkStandard_2021: 103,
  QBE_2022: 3,
  TRUST_2023: 4,
};

// Assignment types - VA
export const ASSIGNMENT_TYPE = {
  Standard: 0,
  Private: 1,
  ProfessionUnder50000: 2,
  ProfessionAbove50000: 3,
};

// Document Types
export const DOCUMENT_TYPE = {
  Generic: 0,
  Image: 1,
  Text: 2,
  Pdf: 3,
  Excel: 4,
  Word: 5,
  Powerpoint: 6,
  Video: 7,
  Numbers: 8,
  Pages: 9,
  Keynote: 10,
  Email: 11,
};

// Document Folder Types
export const DOCUMENT_FOLDER_TYPE = {
  Standard: 0,
  Global: 1,
  Cases: 2,
};

// Product types
export const PRODUCT_TYPES = {
  Standard: 1,
  Custom: 2,
  All: 3,
};

// Product Statuses
export const PRODUCT_STATUS = {
  // Case statuses
  CaseMissingMaterials: 0,
  CaseAwaitingMaterials: 1,
  CaseHasAllMaterials: 2,

  // Product status
  ProductHasNoSupplier: 3,
  ProductNotOrdered: 4,
  ProductOrdered: 5,
};

// Product sorting type
export const PRODUCT_SORT_BY = {
  ProductCategory: 0,
  SupplierName: 1,
  ProductStatus: 2,
};

// Roadmap date intervals
export const DATE_TYPE = {
  WEEKS: 0,
  MONTHS: 1,
  QUATERS: 2,
};

// Roadmap statuses
export const ROADMAP_STATUS = {
  None: 0,
  Active: 1,
  Complete: 2,
  Created: 3,
};

// Roadmap statuses
export const ROADMAP_PROJECT_STATE = {
  Start_Delayed: 0,
  End_Delayed: 1,
  Start_Moved: 2,
  End_Moved: 3,
};

// Roadmap statuses
export const ROADMAP_INFO_TYPE = {
  Generic: 0,
  QualityInsurance: 1,
  Materials: 2,
};

// Roadmap side menu types
export const ROADMAP_TYPE = {
  Generic: 0,
  QualityInsurance: 1,
  Materials: 2,
};

// Sorting types
export const SORT_TYPE = {
  None: 0,
  AZ: 1,
  ZA: 2,
  LowHigh: 3,
  HighLow: 4,
};

// Invitation Types
export const INVITATION_TYPE = {
  Unknown: 0,
  CustomerToUser: 1,
  UserToCustomer: 2,
  FromSystemToUser: 3,
  CustomerToNonExistingUser: 4,
};

// Invitation statuses
export const INVITATION_STATUS = {
  Unknown: 0,
  AwaitingUserResponse: 1,
  AwaitingCustomerResponse: 101,
  Accepted: 2,
  Rejected: 3,
};

// Notification type
export const NOTIFICATION_TYPE = {
  Message: 0,
};

// Notification priority
export const NOTIFICATION_PRIORITY = {
  Low: 0,
  Normal: 1,
  High: 2,
  Extreme: 3,
};

// Notification action type
export const NOTIFICATION_ACTION_TYPE = {
  None: 0,
  Simple: 1,
  Redirect: 2,
};

// Notification subject area
export const NOTIFICATION_SUBJECT_AREA = {
  None: 0,
  VA: 1,
  KS: 2,
  SA: 3,
  Invitation: 4,
  System: 5,
  ExternalLink: 6,
  PDF: 7,
  InvitationRequestAccess: 8,
  InvitationRequestAccepted: 9,
  SchemaSignature: 10,
};

// Notification settings actonis
export const NOTIFICATION_SETTINGS_ACTIONS = {
  UNKNOWN: 0,
  OPENED: 1,
  MARK_ALL_AS_READ: 2,
};

// Schema builder
export const SB_CONTROL_ITEM_TYPE = {
  Unset: 0,
  Standard: 1,
  Custom: 2,
};

export const SB_CONTROL_TYPE = {
  Unset: 0,
  PhotoAreaMarking: 1,
  PhotoDocumentation: 2,
  Signature: 3,
  DateSelector: 4,
  DropdownProducts: 5,
  DropdownUsers: 6,
  DropdownCustom: 7,
  Checkboxes: 8,
  Text: 9,
  TextUneditable: 10,
  Numbers: 11,
  TimeTableUsers: 12,
  Checkmark: 13,
  DropdownCommentPhotos: 14,
  TextPhotos: 15,
};

export const SB_CRITERIA_BEHAVIOR = {
  Unset: 0,
  AND: 1,
  OR: 2,
};

export const SB_CATEGORY_TYPE = {
  Unset: 0,
  Common: 1,
  RiskEvaluation: 2,
  ServiceAgreement: 3,
  ExtraSchema: 4,
};

// For rendering depending on context
export const CONTROL_PLAN_CONTEXT = {
  Global: 0,
  Local: 1,
  OnCase: 2,
};

// MISC
export const SCHEMA_OPTION_FILTER = {
  Unset: 0,
  Common: 1,
  RiskEvaluation: 2,
  ServiceAgreement: 3,
};

// Language categories
export const LANG_TEXT_CATEGORY = {
  Unknown: 0,
  General: 1,
  Mail: 2,
  Sms: 3,
  Push: 4,
};

export const RISK_ASSESSMENT = {
  NotSelected: 0,
  No: 1,
  Yes: 2,
  External: 3,
};

export const INDUSTRY_TYPE = {
  Unknown: 0,
  Roofing: 1,
  Production_Roofing: 2,
};

export const CUSTOMER_EDIT_EVENTS = {
  Unknown: 0,
  Created: 1,
  Paused: 2,
  Activated: 3,
  Closed: 5,
};

export const PDF_FILTER = {
  None: 0,
  CoverPage: 1,
  TableOfContents: 2,
  CaseInformation: 3,
  DEP_CompanyDescription: 4,
  DEP_QualityDescription: 5,
  Building: 6,
  DEP_OperationsAndMaintenance: 7,
  Datasheets: 8,
  SchemaStarted: 9,
  SchemaDone: 10,
  DEP_CaseDocuments: 11,
  DEP_CaseSchemas: 13,
  SchemaAll: 14,
  HidePrivateAssignees: 15,
  Contracts: 16,
  DateInterval: 17,
  BuildingAll: 18,
  SchemaGroupGuid: 19,
  CaseDescription: 20,
  CaseDescriptionAll: 21,
  CaseDocument: 22,
  CaseDocumentAll: 23,
  ControlPlan: 24,
  ControlPlanAll: 25,
  MaterialsOverview: 26,
};
