import React from 'react';
import { connect } from 'react-redux';
import moment from 'moment';

const renderLastEditor = props => {
  if (props.answeredByUserId) {
    const user = props.employees?.find(x => x.id === props.answeredByUserId);

    if (user) {
      const date = moment(props.updatedDate).format('DD.MM.YYYY');

      return (
        <div>
          <small>
            {date} - {user.name}
          </small>
        </div>
      );
    }
  }

  return null;
};

function mapStateToProps({ employees }) {
  return {
    employees: employees.employees,
  };
}

export default connect(mapStateToProps, {})(renderLastEditor);
