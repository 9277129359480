import React, { useState, useEffect, useRef } from 'react';
import i18next from '../../i18n';
import {
  Table,
  Card,
  ButtonDropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Spinner,
} from 'reactstrap';
import moment from 'moment';
import { IoIosArrowDown } from 'react-icons/io';

// Utils
import scrollHook from '../../hocs/scrollHook';
import { calculateBytes } from '../../utils/formatSizeUnits';

// const example = {
//     colgroup: ['10%','20%','auto'],
//     scrollRender: {
//          window: document.getElementById('window-id'),
//          body: document.getElementById('body-id'),
//     },
//     header: {
//         class: '',
//         data: [
//             {
//                 title: 'header 1',
//                 className: '',
//                 tdClass: 'td-class-example',
//                 sortable: true / false,
//                 selector: 'header',
//                 sortType: 'string' / 'number' / 'date'
//             }
//         ]
//     },
//     body: {
//         class: '',
//         empty: 'Listen er tom',
//         data: [
//           { // row 1
//             <-- content -->
//           }
//         ]
//     }
// }

/**
 * Custom react table. Uses "Table" from bootstrap as a base.
 * @param {String} className - classname
 * @param {Array} colgroup - Array describing how much space each column has ie. ['10%', 'auto', '30%']
 * @param {Object} header - Content of the header: { class: '', data: [{ title: '', className: '', tdClass: '', sortTable: true, selector: 'header', sortType: 'string'}]}
 * @param {Object} body - Content of the body: { class: '', empty: 'Listen er tom', data: [<html></html>]}
 * @param {Boolean} striped - Content striped
 * @param {String} size - Table size
 */
const ReactTable = props => {
  const {
    data,
    hover,
    className,
    size,
    striped,
    borderless,
    renderStart = 30,
    renderInterval = 30,
  } = props;
  const [showSort, setShowShort] = useState(null);
  const [sortBy, setSortBy] = useState(null);
  const [sortType, setSortType] = useState(null);
  const [reverse, setReverse] = useState(false);
  const renderCount = useRef(renderStart); //const [renderCount, setRenderCount] = useState(renderStart);
  const renderDone = useRef(false); // const [renderDone, setRenderDone] = useState(false);

  const [renderLoading, setRenderLoading] = useState(false);
  const [renderList, setRenderList] = useState([]);

  useEffect(() => {
    if (getDataProp('scrollRender')) {
      getDataProp('scrollRender').window.addEventListener(
        'scroll',
        handleScrollRender,
      );
    }
    if (getDataProp('body')) {
      setRenderList(getDataProp('body').data);
    }

    return cleanTable;
    // eslint-disable-next-line
  }, []);

  const cleanTable = () => {
    if (getDataProp('scrollRender')) {
      getDataProp('scrollRender').window.removeEventListener(
        'scroll',
        handleScrollRender,
      );
    }
  };

  useEffect(() => {
    if (data?.body?.data) {
      if (data.body !== renderList) {
        //console.log(data.body, renderList);
        setRenderList(data.body.data);
      }
    }
    // eslint-disable-next-line
  }, [data]);

  const handleScrollRender = () => {
    //Any changes here should be made in the other handleScrollRender methods
    let scroll = scrollHook(
      getDataProp('scrollRender').window,
      getDataProp('scrollRender').body,
    );
    if (scroll.progressHeight >= 75) {
      // console.log(renderLoading, renderDone);
      if (!renderLoading && !renderDone.current) {
        renderCount.current = renderCount.current + renderInterval;
        setRenderLoading(true);
        // setRenderUsed(true);
        //console.log('Next render batch', renderCount + renderInterval);
        // TODO fetch call split for much larger batches
        // Need state list handling instead of prop redux
      }
    }
    // Reverse scroll (Not fully tested)
    // if (scroll.progressHeight <= 5) {
    //   if (!renderLoading && renderCount !== 10) {
    //     this.setState({
    //       renderCount: renderCount - renderInterval,
    //       renderLoading: true,
    //     });
    //   }
    // }
  };

  const getDataProp = name => {
    if (!props.data) return null;
    return props.data[name];
  };

  const getColGroups = () => {
    const colgroup = getDataProp('colgroup');
    if (!colgroup) return null;

    return (
      <colgroup>
        {colgroup.map((size, index) => {
          if (size === 'auto') return <col key={index}></col>;
          return <col key={index} style={{ width: size }}></col>;
        })}
      </colgroup>
    );
  };

  const getSortMenu = item => {
    const action = type => {
      setShowShort(null);
      setSortBy(item.selector);
      setSortType(item.sortType);
      setReverse(type);
    };

    const toggle = () => {
      if (showSort !== item.selector) {
        setShowShort(item.selector);
      } else {
        setShowShort(null);
      }
    };

    return (
      <ButtonDropdown
        className='sortmenu'
        isOpen={showSort === item.selector}
        toggle={toggle}
      >
        <DropdownToggle style={{ cursor: 'pointer' }} tag='div'>
          <IoIosArrowDown size={20} />
        </DropdownToggle>
        <DropdownMenu>
          {item.sortType === 'string' && (
            <React.Fragment>
              <DropdownItem onClick={() => action(false)}>
                {i18next.t(9059)}
              </DropdownItem>
              <DropdownItem onClick={() => action(true)}>
                {i18next.t(9060)}
              </DropdownItem>
            </React.Fragment>
          )}
          {item.sortType === 'number' && (
            <React.Fragment>
              <DropdownItem onClick={() => action(false)}>
                {i18next.t(9061)}
              </DropdownItem>
              <DropdownItem onClick={() => action(true)}>
                {i18next.t(9062)}
              </DropdownItem>
            </React.Fragment>
          )}
          {item.sortType === 'date' && (
            <React.Fragment>
              <DropdownItem onClick={() => action(false)}>
                {i18next.t(7098)}
              </DropdownItem>
              <DropdownItem onClick={() => action(true)}>
                {i18next.t(7099)}
              </DropdownItem>
            </React.Fragment>
          )}
          {item.sortType === 'valueDate' && (
            <React.Fragment>
              <DropdownItem onClick={() => action(false)}>
                {i18next.t(7098)}
              </DropdownItem>
              <DropdownItem onClick={() => action(true)}>
                {i18next.t(7099)}
              </DropdownItem>
            </React.Fragment>
          )}
          {item.sortType === 'valueString' && (
            <React.Fragment>
              <DropdownItem onClick={() => action(false)}>
                {i18next.t(9059)}
              </DropdownItem>
              <DropdownItem onClick={() => action(true)}>
                {i18next.t(9060)}
              </DropdownItem>
            </React.Fragment>
          )}
          {item.sortType === 'valueNumber' && (
            <React.Fragment>
              <DropdownItem onClick={() => action(false)}>
                {i18next.t(9061)}
              </DropdownItem>
              <DropdownItem onClick={() => action(true)}>
                {i18next.t(9062)}
              </DropdownItem>
            </React.Fragment>
          )}
          {item.sortType === 'fileSize' && (
            <React.Fragment>
              <DropdownItem onClick={() => action(false)}>
                {i18next.t(9061)}
              </DropdownItem>
              <DropdownItem onClick={() => action(true)}>
                {i18next.t(9062)}
              </DropdownItem>
            </React.Fragment>
          )}
        </DropdownMenu>
      </ButtonDropdown>
    );
  };

  const getSortedData = () => {
    const bodyData = getDataProp('body');
    if (!bodyData) return null;
    if (bodyData.data.length === 0) return null;
    let body = { ...bodyData };
    let sortable = [];
    let nonSortable = [];

    if (sortBy) {
      if (sortType === 'string') {
        body.data.sort((a, b) => {
          // console.log(typeof a[sortBy], typeof b[sortBy]);
          if (typeof a[sortBy] !== 'string' && typeof b[sortBy] !== 'string')
            return 0;
          if (typeof a[sortBy] !== 'string') return -1;
          if (typeof b[sortBy] !== 'string') return 1;

          var textA = a[sortBy].toUpperCase();
          var textB = b[sortBy].toUpperCase();

          return textA < textB ? -1 : textA > textB ? 1 : 0;
        });
        if (reverse) {
          body.data.reverse();
        }
      } else if (sortType === 'number') {
        if (!reverse) {
          body.data.sort((a, b) => {
            var numberA = a[sortBy];
            var numberB = b[sortBy];

            return numberA < numberB ? -1 : numberA > numberB ? 1 : 0;
          });
        } else {
          body.data.sort((a, b) => {
            var numberA = a[sortBy];
            var numberB = b[sortBy];

            return numberA > numberB ? -1 : numberA < numberB ? 1 : 0;
          });
        }
      } else if (sortType === 'date') {
        if (!reverse) {
          sortable = body.data.filter(x => x.date !== '');
          nonSortable = body.data.filter(x => x.date === '');
          sortable.sort((a, b) => {
            var parts = a[sortBy].split('.');
            var bparts = b[sortBy].split('.');
            var aDate = new Date(parts[2], parts[1], parts[0]);
            var bDate = new Date(bparts[2], bparts[1], bparts[0]);
            return moment(aDate).isAfter(moment(bDate)) ? 1 : -1;
          });
          body.data = [...sortable, ...nonSortable];
        } else {
          sortable = body.data.filter(x => x.date !== '');
          nonSortable = body.data.filter(x => x.date === '');
          sortable.sort((a, b) => {
            var parts = a[sortBy].split('.');
            var bparts = b[sortBy].split('.');
            var aDate = new Date(parts[2], parts[1], parts[0]);
            var bDate = new Date(bparts[2], bparts[1], bparts[0]);
            return moment(aDate).isBefore(moment(bDate)) ? 1 : -1;
          });
          body.data = [...sortable, ...nonSortable];
        }
      } else if (sortType === 'valueDate') {
        if (!reverse) {
          sortable = body.data.filter(x => x.date !== '');
          nonSortable = body.data.filter(x => x.date === '');
          sortable.sort((a, b) => {
            var aDate = a[sortBy].props.value;
            var bDate = b[sortBy].props.value;
            return moment(aDate).isAfter(moment(bDate)) ? 1 : -1;
          });
          body.data = [...sortable, ...nonSortable];
        } else {
          sortable = body.data.filter(x => x.date !== '');
          nonSortable = body.data.filter(x => x.date === '');
          sortable.sort((a, b) => {
            var aDate = a[sortBy].props.value;
            var bDate = b[sortBy].props.value;
            return moment(aDate).isBefore(moment(bDate)) ? 1 : -1;
          });
          body.data = [...sortable, ...nonSortable];
        }
      } else if (sortType === 'valueString') {
        body.data.sort((a, b) => {
          var textA = a[sortBy].props.value?.toUpperCase();
          var textB = b[sortBy].props.value?.toUpperCase();

          return textA < textB ? -1 : textA > textB ? 1 : 0;
        });
        if (reverse) {
          body.data.reverse();
        }
      } else if (sortType === 'valueNumber') {
        if (!reverse) {
          body.data.sort((a, b) => {
            var numberA = a[sortBy].props.value;
            var numberB = b[sortBy].props.value;

            return numberA < numberB ? -1 : numberA > numberB ? 1 : 0;
          });
        } else {
          body.data.sort((a, b) => {
            var numberA = a[sortBy].props.value;
            var numberB = b[sortBy].props.value;

            return numberA > numberB ? -1 : numberA < numberB ? 1 : 0;
          });
        }
      } else if (sortType === 'fileSize') {
        body.data.sort((a, b) => {
          var bytesA = 0;
          var bytesB = 0;
          var sizeA = a[sortBy].split(' ');
          var sizeB = b[sortBy].split(' ');

          if (sizeA.length > 1) {
            bytesA = calculateBytes(sizeA[0], sizeA[1]);
          }
          if (sizeB.length > 1) {
            bytesB = calculateBytes(sizeB[0], sizeB[1]);
          }

          return bytesA < bytesB ? -1 : bytesA > bytesB ? 1 : 0;
        });
        if (reverse) {
          body.data.reverse();
        }
      }
    }

    return body;
  };

  const renderHeaders = () => {
    const headers = getDataProp('headers');
    if (!headers) return null;

    const renderHeader = (header, index) => {
      return (
        <th key={index} className={header.className ? header.className : null}>
          {header.title} {header.sortable ? getSortMenu(header) : null}
        </th>
      );
    };

    return (
      <thead className={'header ' + headers.class}>
        <tr>{headers.data.map(renderHeader)}</tr>
      </thead>
    );
  };

  const renderBody = () => {
    const body = getSortedData();
    if (!body) return null;
    if (body.data.length === 0) return null;

    const renderRow = (item, i) => {
      if (
        !(i <= renderCount.current) &&
        getDataProp('scrollRender')
        //&& key >= renderCount - 20
      ) {
        return null;
      }
      // Ready for next loading chunk
      if (
        i === renderCount.current &&
        renderLoading &&
        getDataProp('scrollRender')
      ) {
        setRenderLoading(false);
      }
      // Reached the end
      if (
        i + 1 === body.data.length &&
        renderLoading &&
        getDataProp('scrollRender')
      ) {
        //console.log('done');
        setRenderLoading(false);
        renderDone.current = true;
      }

      const renderColumn = (key, l) => {
        let headers = getDataProp('headers');
        let tdClass = headers.data[l]?.tdClass;
        let onClick = headers.data[l]?.onClick;
        if (key === '_id') return null;
        if (key === '_onClick') return null;

        return (
          <td
            key={l}
            className={(tdClass ? tdClass : '') + (onClick ? ' clickable' : '')}
            onClick={onClick ? () => onClick(item['_id']) : null}
          >
            {item[key]}
          </td>
        );
      };

      const action = item._onClick;
      let classes = action ? 'clickable-row' : null;
      if (hover) {
        if (!classes) {
          classes = 'hover';
        } else {
          classes += ' hover';
        }
      }

      return (
        <tr key={i} onClick={action} className={classes}>
          {Object.keys(item).map(renderColumn)}
        </tr>
      );
    };

    return (
      <tbody className={'body ' + body.class}>{body.data.map(renderRow)}</tbody>
    );
  };

  const renderTable = () => {
    return (
      <React.Fragment>
        <Table
          className={'custom-table ' + (className ? className : '')}
          size={size}
          striped={striped}
          borderless={borderless}
        >
          {getColGroups()}
          {renderHeaders()}
          {renderBody()}
        </Table>
        {getDataProp('scrollRender') && (
          <React.Fragment>
            {/* {!renderLoading && renderDone && renderUsed && (
              <div className='no-more-items'>{i18next.t(7187)}</div>
            )} */}
            {renderLoading && (
              <div className='no-more-items'>
                <Spinner />
              </div>
            )}
          </React.Fragment>
        )}
      </React.Fragment>
    );
  };

  const renderEmpty = () => {
    const body = getDataProp('body');
    if (!body) return null;
    if (body.data.length > 0) return null;

    let text = i18next.t(9079);

    if (body.empty?.trim().length > 0) {
      text = body.empty;
    }

    return (
      <div className='empty-body'>
        <div className='text'>{text}</div>
      </div>
    );
  };

  return (
    <Card className='custom-table-wrapper'>
      {!!data && renderTable()}
      {renderEmpty()}
    </Card>
  );
};

export default ReactTable;
