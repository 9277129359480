import { Badge } from 'reactstrap';
import React from 'react';
import i18next from '../i18n';
import CountryList from 'countries-list';
import { CASE_STATUS, MARKING_TYPE, PRODUCT_STATUS } from '../redux/constants';
import { v4 as uuidv4 } from 'uuid';
import { getMarkingSizeByLadder } from './imageMarkingUtils';

export const badgeByCaseStatus = status => {
  let text, color;

  switch (status) {
    case CASE_STATUS.NotAccepted:
      text = i18next.t(1186);
      color = 'grey';
      break;
    case CASE_STATUS.Accepted:
      text = i18next.t(1001);
      color = 'success';
      break;
    case CASE_STATUS.InProgress:
      text = i18next.t(187);
      color = 'info';
      break;
    case CASE_STATUS.Done:
      text = i18next.t(1003);
      color = 'success';
      break;
    case CASE_STATUS.Created:
      text = i18next.t(1187);
      color = 'grey';
      break;
    case CASE_STATUS.Active:
      text = i18next.t(71);
      color = 'warning';
      break;
    case CASE_STATUS.Invoiced:
      text = i18next.t(6074);
      color = 'info';
      break;
    case CASE_STATUS.MissingControls:
      text = i18next.t(6090);
      color = 'grey';
      break;
    case CASE_STATUS.IncompleteControl:
      text = i18next.t(6089);
      color = 'warning';
      break;
    case CASE_STATUS.CompletedControl:
      text = i18next.t(6119);
      color = 'success';
      break;
    case CASE_STATUS.Draft:
      text = i18next.t(1004);
      color = 'warning';
      break;
    case CASE_STATUS.Subscribed:
      text = i18next.t(71);
      color = 'light';
      break;
    case CASE_STATUS.Unsubscribed:
      text = i18next.t(72);
      color = 'light';
      break;
    case CASE_STATUS.None:
    case CASE_STATUS.NullState:
    default:
      text = i18next.t(1005);
      color = 'info';
      break;
  }

  return (
    <Badge className='badge_user' color={color} value={text}>
      {text}
    </Badge>
  );
};

export const ColorlessBadge = (text, noBorders = false) => {
  let classes = 'badge_user colorless';
  if (noBorders) {
    classes += ' borderless';
  }

  return <Badge className={classes}>{text}</Badge>;
};

export const getTimeIntervalOptions = () => {
  let i = 0;
  return [
    <option key={i++}>00:00</option>,
    <option key={i++}>00:15</option>,
    <option key={i++}>00:30</option>,
    <option key={i++}>00:45</option>,
    <option key={i++}>01:00</option>,
    <option key={i++}>01:15</option>,
    <option key={i++}>01:30</option>,
    <option key={i++}>01:45</option>,
    <option key={i++}>02:00</option>,
    <option key={i++}>02:15</option>,
    <option key={i++}>02:30</option>,
    <option key={i++}>02:45</option>,
    <option key={i++}>03:00</option>,
    <option key={i++}>03:15</option>,
    <option key={i++}>03:30</option>,
    <option key={i++}>03:45</option>,
    <option key={i++}>04:00</option>,
    <option key={i++}>04:15</option>,
    <option key={i++}>04:30</option>,
    <option key={i++}>04:45</option>,
    <option key={i++}>05:00</option>,
    <option key={i++}>05:15</option>,
    <option key={i++}>05:30</option>,
    <option key={i++}>05:45</option>,
    <option key={i++}>06:00</option>,
    <option key={i++}>06:15</option>,
    <option key={i++}>06:30</option>,
    <option key={i++}>06:45</option>,
    <option key={i++}>07:00</option>,
    <option key={i++}>07:15</option>,
    <option key={i++}>07:30</option>,
    <option key={i++}>07:45</option>,
    <option key={i++}>08:00</option>,
    <option key={i++}>08:15</option>,
    <option key={i++}>08:30</option>,
    <option key={i++}>08:45</option>,
    <option key={i++}>09:00</option>,
    <option key={i++}>09:15</option>,
    <option key={i++}>09:30</option>,
    <option key={i++}>09:45</option>,
    <option key={i++}>10:00</option>,
    <option key={i++}>10:15</option>,
    <option key={i++}>10:30</option>,
    <option key={i++}>10:45</option>,
    <option key={i++}>11:00</option>,
    <option key={i++}>11:15</option>,
    <option key={i++}>11:30</option>,
    <option key={i++}>11:45</option>,
    <option key={i++}>12:00</option>,
    <option key={i++}>12:15</option>,
    <option key={i++}>12:30</option>,
    <option key={i++}>12:45</option>,
    <option key={i++}>13:00</option>,
    <option key={i++}>13:15</option>,
    <option key={i++}>13:30</option>,
    <option key={i++}>13:45</option>,
    <option key={i++}>14:00</option>,
    <option key={i++}>14:15</option>,
    <option key={i++}>14:30</option>,
    <option key={i++}>14:45</option>,
    <option key={i++}>15:00</option>,
    <option key={i++}>15:15</option>,
    <option key={i++}>15:30</option>,
    <option key={i++}>15:45</option>,
    <option key={i++}>16:00</option>,
    <option key={i++}>16:15</option>,
    <option key={i++}>16:30</option>,
    <option key={i++}>16:45</option>,
    <option key={i++}>17:00</option>,
    <option key={i++}>17:15</option>,
    <option key={i++}>17:30</option>,
    <option key={i++}>17:45</option>,
    <option key={i++}>18:00</option>,
    <option key={i++}>18:15</option>,
    <option key={i++}>18:30</option>,
    <option key={i++}>18:45</option>,
    <option key={i++}>19:00</option>,
    <option key={i++}>19:15</option>,
    <option key={i++}>19:30</option>,
    <option key={i++}>19:45</option>,
    <option key={i++}>20:00</option>,
    <option key={i++}>20:15</option>,
    <option key={i++}>20:30</option>,
    <option key={i++}>20:45</option>,
    <option key={i++}>21:00</option>,
    <option key={i++}>21:15</option>,
    <option key={i++}>21:30</option>,
    <option key={i++}>21:45</option>,
    <option key={i++}>22:00</option>,
    <option key={i++}>22:15</option>,
    <option key={i++}>22:30</option>,
    <option key={i++}>22:45</option>,
    <option key={i++}>23:00</option>,
    <option key={i++}>23:15</option>,
    <option key={i++}>23:30</option>,
    <option key={i++}>23:45</option>,
  ];
};

export const getProductUnitOptions = () => {
  return [
    {
      label: 'CM',
      value: 'CM',
    },
    {
      label: 'Meter',
      value: 'Meter',
    },
    {
      label: 'Kg',
      value: 'Kg',
    },
    {
      label: 'Liter',
      value: 'Liter',
    },
  ];
};

export const getProductStatusOptions = (status = null) => {
  if (!status) {
    return [
      {
        label: i18next.t(9042),
        value: PRODUCT_STATUS.ProductHasNoSupplier,
      },
      {
        label: i18next.t(9043),
        value: PRODUCT_STATUS.ProductNotOrdered,
      },
      {
        label: i18next.t(9044),
        value: PRODUCT_STATUS.ProductOrdered,
      },
    ];
  } else if (status === PRODUCT_STATUS.ProductHasNoSupplier) {
    return [
      {
        label: i18next.t(9042),
        value: PRODUCT_STATUS.ProductHasNoSupplier,
      },
      {
        label: i18next.t(9043),
        value: PRODUCT_STATUS.ProductNotOrdered,
      },
      // {
      //   label: i18next.t(9044),
      //   value: PRODUCT_STATUS.ProductOrdered,
      // },
    ];
  } else if (status === PRODUCT_STATUS.ProductNotOrdered) {
    return [
      // {
      //   label: i18next.t(9042),
      //   value: PRODUCT_STATUS.ProductHasNoSupplier,
      // },
      {
        label: i18next.t(9043),
        value: PRODUCT_STATUS.ProductNotOrdered,
      },
      {
        label: i18next.t(9044),
        value: PRODUCT_STATUS.ProductOrdered,
      },
    ];
  } else if (status === PRODUCT_STATUS.ProductOrdered) {
    return [
      // {
      //   label: i18next.t(9042),
      //   value: PRODUCT_STATUS.ProductHasNoSupplier,
      // },
      // {
      //   label: i18next.t(9043),
      //   value: PRODUCT_STATUS.ProductNotOrdered,
      // },
      {
        label: i18next.t(9044),
        value: PRODUCT_STATUS.ProductOrdered,
      },
    ];
  }
};

export const getPlaceholderIfEmpty = text => {
  if (typeof text !== 'string') return text;
  if (text.trim().length > 0) return text;

  return <p className='empty-content'>{i18next.t(8184)}</p>;
};

export const isEmpty = text => {
  if (typeof text !== 'string') return false;
  if (text.trim().length > 0) return false;
  return true;
};

export const getCountryCodeOptions = () => {
  return Object.keys(CountryList.countries).map((country, index) => {
    const obj = CountryList.countries[country];
    const value = `+${obj.phone}`;
    const name = `${obj.emoji} ${value}`;

    return { label: name, value };
  });
};

////////////////////////////////////////////////////////////////////////////
/////// Create UploadMarkingReferenceRequestObj
export const createUploadMarkingReferenceRequestObj = (
  fileId,
  controlId,
  markings,
  bindings,
) => {
  let dataObj = {
    fileId,
    controlId,
  };

  // console.log({ markings, bindings });

  const grouped = [];
  const ungrouped = [];

  Object.keys(bindings).forEach(x =>
    bindings[x].isGroup ? grouped.push(x) : ungrouped.push(x),
  );

  // Build points list
  const points = ungrouped.map(x => {
    return {
      displayId: bindings[x].index + '',
      refIds: bindings[x].refIds,
      markings: markings.filter(y => y.guid === x),
    };
  });

  // Build polygons list
  const polygons = grouped.map((x, idx) => {
    return {
      displayId: bindings[x].index + '',
      refIds: bindings[x].refIds,
      path: markings.filter(y => y.guid === x),
      index: idx + 1,
    };
  });

  dataObj = {
    ...dataObj,
    points,
    polygons,
  };

  return dataObj;
};

////////////////////////////////////////////////////////////////////////////
/////// Translate image reference objects to marking objects
export const createMarkingObjectsFromReferences = (
  currentFile,
  imgDimensions,
  refs,
  initialGuid,
  prefix,
) => {
  // Variables
  let currentGuid = initialGuid;
  let currentGroupId = null;
  let currentMarking = null;

  let markings = [];
  let groups = [];
  let imgGuids = {};
  let guids = [];
  let bindings = {};

  // Internal methods
  const getMarkingObj = () => {
    const radius = getMarkingSizeByLadder(currentFile);
    return {
      id: '_' + Math.random() * 1000, // Random id for handling
      groupId: currentGroupId ? currentGroupId : '_' + Math.random() * 1000,
      x: 20,
      y: 20,
      width: 20,
      height: 20,
      shape: MARKING_TYPE.Circle,
      r: radius,
      dimensions: imgDimensions,
      filled: false,
      hidden: false,
      guid: currentGuid,
      index: guids.length + 1,
    };
  };

  const addMarking = pos => {
    let newMarking = getMarkingObj(currentGroupId);
    newMarking.x = pos.x;
    newMarking.y = pos.y;

    const scaling = imgDimensions.height / imgDimensions.naturalHeight;

    newMarking.x /= scaling;
    newMarking.y /= scaling;

    const tmpMarkings = [...markings, newMarking];
    const tmpCurrentMarking = newMarking.id;

    markings = tmpMarkings;
    currentMarking = tmpCurrentMarking;

    if (!currentGroupId) {
      currentGroupId = newMarking.groupId;
    }
  };

  const connectGroup = () => {
    if (!currentGroupId) return;

    let updatedGroups = groups.slice();
    const group = markings.filter(x => x.groupId === currentGroupId);

    const updatedMarkings = markings.map(x => {
      return {
        ...x,
        hidden: x.groupId === currentGroupId || x.hidden,
      };
    });

    if (group?.length > 0) {
      updatedGroups.push(group);
    }

    let updateGuid = uuidv4();
    let updatedBindings = {
      ...bindings,
      [updateGuid]: {
        refIds: [],
        index: guids.length + 2,
        isGroup: false,
        displayId: prefix + (guids.length + 2),
      },
    };

    updatedBindings[currentGuid].isGroup = true;

    currentMarking = null;
    currentGroupId = null;
    groups = updatedGroups;
    markings = updatedMarkings;
    guids = [...guids, currentGuid];
    currentGuid = updateGuid;
    bindings = updatedBindings;
  };

  // Create indexed list
  let indexedMarks = {};
  // console.log(refs);

  refs.points.forEach(x => {
    if (!!indexedMarks[x.displayId]) {
      indexedMarks[x.displayId].push(x);
    } else {
      indexedMarks[x.displayId] = [x];
    }
  });

  refs.polygons.forEach(x => {
    if (!!indexedMarks[x.displayId]) {
      indexedMarks[x.displayId].images.push(...x.images);
    } else {
      indexedMarks[x.displayId] = { ...x, isGroup: true };
    }
  });

  // Parse indexed list
  let regex = /\[([\d.]+);([\d.]+)\]/gi;

  Object.keys(indexedMarks).forEach(k => {
    const item = indexedMarks[k];

    if (item.isGroup) {
      // console.log('group', item);
      // Polygon image bindings
      if (!!bindings[currentGuid]) {
        if (item.images) {
          let tmpIds = item.images.map(x => x.caseFileId);
          tmpIds = [...new Set([...bindings[currentGuid].refIds, ...tmpIds])];
          tmpIds.forEach(x => (imgGuids[x] = currentGuid));
          bindings[currentGuid].refIds = tmpIds;
        }
      } else {
        const imgIds = item.images.map(x => x.caseFileId);
        imgIds.forEach(x => (imgGuids[x] = currentGuid));

        bindings[currentGuid] = {
          refIds: imgIds,
          index: parseInt(item.displayId),
          displayId: prefix + item.displayId,
        };
      }

      // Polygons
      const fixedPath = item.path.replaceAll(',', '.');
      let pathCoords = [];
      let match;
      while ((match = regex.exec(fixedPath)) !== null) {
        const x = parseFloat(match[1]);
        const y = parseFloat(match[2]);
        pathCoords.push({ x, y });
      }
      // console.log(pathCoords);

      pathCoords.forEach(coords => {
        const pCords = translateXY(imgDimensions, coords.x, coords.y);
        addMarking(pCords);
      });

      connectGroup();
    } else {
      // console.log('point', item);
      // Points image bindings
      if (!!bindings[currentGuid]) {
        let tmpIds = [];
        item.forEach(x => {
          tmpIds = [
            ...new Set([...tmpIds, ...x.images.map(y => y.caseFileId)]),
          ];
        });

        tmpIds = [...new Set([...bindings[currentGuid].refIds, ...tmpIds])];
        bindings[currentGuid].refIds = tmpIds;
        tmpIds.forEach(x => (imgGuids[x] = currentGuid));
      } else {
        let tmpIds = [];
        item.forEach(x => {
          tmpIds = [
            ...new Set([...tmpIds, ...x.images.map(y => y.caseFileId)]),
          ];
        });
        tmpIds.forEach(x => (imgGuids[x] = currentGuid));

        if (item.length > 0) {
          const parsed = parseInt(item[0].displayId);
          bindings[currentGuid] = {
            refIds: tmpIds,
            index: parsed,
            displayId: prefix + parsed,
          };
        }
      }

      // Points
      item.forEach(coords => {
        const pCords = translateXY(imgDimensions, coords.x, coords.y);
        addMarking(pCords);
      });
    }
  });

  // Result object
  return {
    markings,
    groups,
    imgGuids,
    guids,
    bindings,
    currentMarking,
    currentGroupId,
    currentGuid,
  };
};

const translateXY = (imgDimensions, x, y) => {
  const scaling = imgDimensions.height / imgDimensions.naturalHeight;
  const newX = x * scaling;
  const newY = y * scaling;
  // const newX = x;
  // const newY = y;

  // console.log(imgDimensions, scaling, x, y, newX, newY);

  return {
    x: newX,
    y: newY,
  };
};

export const createGalleryObjectsFromReferences = (
  refs,
  initialGuid,
  prefix,
) => {
  // Variables
  let currentGuid = initialGuid;
  let currentGroupId = null;

  let markings = [];
  let groups = [];
  let imgGuids = {};
  let guids = [];
  let bindings = {};

  // Internal methods
  const getMarkingObj = () => {
    return {
      id: '_' + Math.random() * 1000, // Random id for handling
      groupId: currentGroupId ? currentGroupId : '_' + Math.random() * 1000,
      shape: MARKING_TYPE.Circle,
      hidden: false,
      guid: currentGuid,
      index: guids.length + 1,
    };
  };

  const addMarking = () => {
    let newMarking = getMarkingObj(currentGroupId);

    const tmpMarkings = [...markings, newMarking];
    markings = tmpMarkings;

    if (!currentGroupId) {
      currentGroupId = newMarking.groupId;
    }
  };

  const connectGroup = () => {
    if (!currentGroupId) return;

    let updatedGroups = groups.slice();
    const group = markings.filter(x => x.groupId === currentGroupId);

    const updatedMarkings = markings.map(x => {
      return {
        ...x,
        hidden: x.groupId === currentGroupId || x.hidden,
      };
    });

    if (group?.length > 0) {
      updatedGroups.push(group);
    }

    let updateGuid = uuidv4();
    let updatedBindings = {
      ...bindings,
      [updateGuid]: {
        refIds: [],
        index: guids.length + 2,
        isGroup: false,
        displayId: prefix + (guids.length + 2),
      },
    };

    updatedBindings[currentGuid].isGroup = true;

    currentGroupId = null;
    groups = updatedGroups;
    markings = updatedMarkings;
    guids = [...guids, currentGuid];
    currentGuid = updateGuid;
    bindings = updatedBindings;
  };

  // Create indexed list
  let indexedMarks = {};
  // console.log(refs);

  refs.points.forEach(x => {
    if (!!indexedMarks[x.displayId]) {
      indexedMarks[x.displayId].push(x);
    } else {
      indexedMarks[x.displayId] = [x];
    }
  });

  refs.polygons.forEach(x => {
    if (!!indexedMarks[x.displayId]) {
      indexedMarks[x.displayId].images.push(...x.images);
    } else {
      indexedMarks[x.displayId] = { ...x, isGroup: true };
    }
  });

  // Parse indexed list
  let regex = /\[([\d.]+);([\d.]+)\]/gi;

  Object.keys(indexedMarks).forEach(k => {
    const item = indexedMarks[k];

    if (item.isGroup) {
      // console.log('group', item);
      // Polygon image bindings
      if (!!bindings[currentGuid]) {
        if (item.images) {
          let tmpIds = item.images.map(x => x.caseFileId);
          tmpIds = [...new Set([...bindings[currentGuid].refIds, ...tmpIds])];
          tmpIds.forEach(x => (imgGuids[x] = currentGuid));
          bindings[currentGuid].refIds = tmpIds;
        }
      } else {
        const imgIds = item.images.map(x => x.caseFileId);
        imgIds.forEach(x => (imgGuids[x] = currentGuid));

        bindings[currentGuid] = {
          refIds: imgIds,
          index: parseInt(item.displayId),
          displayId: prefix + item.displayId,
        };
      }

      // Polygons
      const fixedPath = item.path.replaceAll(',', '.');
      // eslint-disable-next-line no-unused-vars
      let match;
      while ((match = regex.exec(fixedPath)) !== null) {
        addMarking();
      }

      connectGroup();
    } else {
      // console.log('point', item);
      // Points image bindings
      if (!!bindings[currentGuid]) {
        let tmpIds = [];
        item.forEach(x => {
          tmpIds = [
            ...new Set([...tmpIds, ...x.images.map(y => y.caseFileId)]),
          ];
        });

        tmpIds = [...new Set([...bindings[currentGuid].refIds, ...tmpIds])];
        bindings[currentGuid].refIds = tmpIds;
        tmpIds.forEach(x => (imgGuids[x] = currentGuid));
      } else {
        let tmpIds = [];
        item.forEach(x => {
          tmpIds = [
            ...new Set([...tmpIds, ...x.images.map(y => y.caseFileId)]),
          ];
        });
        tmpIds.forEach(x => (imgGuids[x] = currentGuid));

        if (item.length > 0) {
          const parsed = parseInt(item[0].displayId);
          bindings[currentGuid] = {
            refIds: tmpIds,
            index: parsed,
            displayId: prefix + parsed,
          };
        }
      }

      // Points
      item.forEach(addMarking);
    }
  });

  // Result object
  return {
    imgGuids,
    bindings,
  };
};

export const getImageRotated = (image, degrees, cb) => {
  var img = new Image();

  // onload fires when the image is fully loadded, and has width and height
  img.onload = function() {
    var canvas = document.createElement('canvas');
    var ctx = canvas.getContext('2d');
    canvas.style.width = '20%';

    if (degrees === 90 || degrees === 270) {
      canvas.width = image.height;
      canvas.height = image.width;
    } else {
      canvas.width = image.width;
      canvas.height = image.height;
    }

    ctx.clearRect(0, 0, canvas.width, canvas.height);
    if (degrees === 90 || degrees === 270) {
      ctx.translate(image.height / 2, image.width / 2);
    } else {
      ctx.translate(image.width / 2, image.height / 2);
    }
    ctx.rotate((degrees * Math.PI) / 180);
    ctx.drawImage(img, -image.width / 2, -image.height / 2);

    var dataURL = '';
    if (image.fileName.endsWith('.png')) {
      dataURL = canvas.toDataURL('image/png');
    } else if (image.fileName.endsWith('.jpg')) {
      dataURL = canvas.toDataURL('image/jpg');
    } else if (image.fileName.endsWith('.jpeg')) {
      dataURL = canvas.toDataURL('image/jpeg');
    }

    console.log(canvas.height, canvas.width);
    cb(dataURL, canvas.height, canvas.width);
  };

  // set attributes and src
  img.setAttribute('crossOrigin', 'anonymous'); //
  img.src = image.fileUrl;
};
